import React from 'react';

import { MenuItem } from '../../components';
import { data } from '../../constants';
import './Menu.css';

const Menu = () => (
  <div className="app__menu flex__center section__padding" id="menu">
    <div className="app__menu-title">
      <p className="p__alegreya_h1" style={{ color: '#DCCA87' }}>Menu</p>
    </div>

    <div className="app__menu-menu">

      <div className="app__menu-menu_cake  flex__center">
        <p className="p__alegreya_h2">Jinny Pound Cakes</p>
        <div className="app__menu_menu_items">
          {data.cakes.map((cake, index) => (
            <MenuItem key={cake.title + index} title={cake.title} price={cake.price} tags={cake.tags} />
          ))}
        </div>
        <p className="p__alegreya_h2">Whole Pound Cake</p>
        <p className="p__alegreya_h3">* 24 hour notice</p>
        <div className="app__menu_menu_items">
          {data.poundCakes.map((poundCake, index) => (
            <MenuItem key={poundCake.title + index} title={poundCake.title} price={poundCake.price} tags={poundCake.tags} />
          ))}
        </div>
      </div>

      <div className="app__menu_menu_divider" />

      <div className="app__menu-menu_drinks  flex__center">
        <p className="p__alegreya_h2">Drinks</p>
        <br />
        <p className="p__alegreya_h3">Coffee | Latte</p>
        <div className="app__menu_menu_items">
          {data.coffees.map((coffee, index) => (
            <MenuItem key={coffee.title + index} title={coffee.title} price={coffee.price} tags={coffee.tags} />
          ))}
        </div>

        <p className="p__alegreya_h3">Tea</p>
        <div className="app__menu_menu_items">
          {data.teas.map((tea, index) => (
            <MenuItem key={tea.title + index} title={tea.title} price={tea.price} tags={tea.tags} />
          ))}
        </div>

        <p className="p__alegreya_h3">Lemonade</p>
        <div className="app__menu_menu_items">
          {data.lemonades.map((lemonade, index) => (
            <MenuItem key={lemonade.title + index} title={lemonade.title} price={lemonade.price} tags={lemonade.tags} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Menu;
