import images from './images';

const cakes = [
  {
    title: 'Original',
    price: '$3.95',
    tags: 'Walnut | 5 x 5 x 7cm',
  },
  {
    title: 'Lemon',
    price: '$3.95',
    tags: '5 x 5 x 7cm',
  },
  {
    title: 'Earl Grey',
    price: '$3.95',
    tags: '5 x 5 x 7cm',
  },
  {
    title: 'Cream Cheese',
    price: '$3.95',
    tags: '5 x 5 x 7cm',
  },
  {
    title: 'Matcha',
    price: '$3.95',
    tags: 'Green Tea | 5 x 5 x 7cm',
  },
  {
    title: 'Coffee',
    price: '$3.95',
    tags: '5 x 5 x 7cm',
  },
  {
    title: 'Chocolate',
    price: '$3.95',
    tags: '5 x 5 x 7cm',
  },
  {
    title: 'Egg',
    price: '$3.95',
    tags: '5 x 5 x 7cm',
  },
  {
    title: 'Black Sesame',
    price: '$3.95',
    tags: '5 x 5 x 7cm',
  },

];

const poundCakes = [
  {
    title: 'Original',
    price: '$24.00',
    tags: 'Walnut | 21 x 11.5 x 9cm',
  },
  {
    title: 'Lemon',
    price: '$24.00',
    tags: '21 x 11.5 x 9cm',
  },
  {
    title: 'Earl Grey',
    price: '$24.00',
    tags: '21 x 11.5 x 9cm',
  },
  {
    title: 'Coffee',
    price: '$24.00',
    tags: '21 x 11.5 x 9cm',
  },
  {
    title: 'Matcha',
    price: '$24.00',
    tags: 'Green Tea | 21 x 11.5 x 9cm',
  },
  {
    title: 'Chocolate',
    price: '$24.00',
    tags: '21 x 11.5 x 9cm',
  },
];

const coffees = [
  {
    title: 'Hand Drip Coffee',
    price: '$3.50',
    tags: 'Cold | Hot',
  },
  {
    title: 'Vienna Coffee',
    price: '$4.00',
    tags: 'Hot',
  },
  {
    title: 'Matcha Latte',
    price: '$4.25',
    tags: 'Cold',
  },
];

const teas = [
  {
    title: 'Lemon Tea',
    price: '$3.50',
    tags: 'Hot',
  },
  {
    title: 'Green Tea',
    price: '$3.00',
    tags: 'Cold | Hot',
  },
  {
    title: 'Solomon\'s Seal Tea',
    price: '$3.00',
    tags: 'Cold | Hot',
  },
  {
    title: 'English Breakfast Tea',
    price: '$3.00',
    tags: 'Cold | Hot',
  },
  {
    title: 'Corn Silk Tea',
    price: '$3.00',
    tags: 'Cold | Hot',
  },
];

const lemonades = [
  {
    title: 'Lemonade',
    price: '$3.50',
    tags: 'Cold',
  },
  {
    title: 'Peach Lemonade',
    price: '$4.00',
    tags: 'Cold',
  },
  {
    title: 'Hibiscus Lemonade',
    price: '$4.00',
    tags: 'Cold',
  },
  {
    title: 'Strawberry Ade',
    price: '$3.50',
    tags: 'Cold',
  },
];

const specials = [
  {
    imgUrlSmall: images.special1Small,
    imgUrl: images.special1,
    imgUrlLarge: images.special1Large,
    title: 'Pound Cake + Drink',
    price: '$6.50',
  },
  {
    imgUrlSmall: images.special2Small,
    imgUrl: images.special2,
    imgUrlLarge: images.special2Large,
    title: '4 Pound Cakes',
    price: '$14.00',
  },
  {
    imgUrlSmall: images.special3Small,
    imgUrl: images.special3,
    imgUrlLarge: images.special3Large,
    title: '8 Pound Cakes',
    price: '$28.00',
  },
];

export default { cakes, poundCakes, coffees, teas, lemonades, specials };
