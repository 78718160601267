import React from 'react';

import './SpecialItem.css';

const SpecialItem = ({ imageUrl, imageUrlSmall, imageUrlLarge, title, price }) => (
  <div className="app__specialitem">
    <div className="app__specialitem-image">
      <picture>
        <source media="(min-width: 1150px)" srcSet={imageUrlLarge} />
        <source media="(min-width: 660px)" srcSet={imageUrl} />
        <source media="(max-width: 660px)" srcSet={imageUrlSmall} />
        <img src={imageUrlSmall} />
      </picture>
    </div>
    <div className="app__specialitem-sub">
      <p1 className="p__alegreya_h2">{title}</p1>
      <p2 className="p__alegreya_h2">{price}</p2>
    </div>
  </div>
);

export default SpecialItem;
