import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';

import { images } from '../../constants';
import './ContactUs.css';

const ContactUs = () => (
  <div className="app__contactus section__padding" id="contactus">

    <div className="app__contactus-links">

      <div className="app__contactus-links_logo">
        <img src={images.logo} />
        <p className="p__opensans_s1">jinnycakecafe@gmail.com</p>
        <div className="app__contactus-links_icons">
          <a href="https://www.facebook.com/Jinny-Cake-Cafe-101340949370634/" target="_blank" rel="noopener noreferrer">
            <FiFacebook />
          </a>
          <FiTwitter />
          <a href="https://www.instagram.com/jinny_cake_cafe_/" target="_blank" rel="noopener noreferrer">
            <FiInstagram />
          </a>
        </div>
      </div>

      <div className="app__contactus-links_map">
        <a href="https://www.google.ca/maps/place/Jinny+Cake+Cafe/@43.471593,-79.7418058,15z/data=!4m5!3m4!1s0x0:0xdf0997cddb38cbcc!8m2!3d43.471593!4d-79.7418058" target="_blank" rel="noopener noreferrer">
          <img src={images.map} />
          <p className="p__alegreya_h4">Contact Us</p>
          <p className="p__opensans_s1">3075 George Savage Ave</p>
          <p className="p__opensans_s1">Oakville, ON</p>
        </a>
      </div>

      <div className="app__contactus-links_hours">
        <img src={images.clock} style={{ width: 50 }} />
        <p className="p__alegreya_h4">Working Hours</p>
        <div>
          <p className="p__opensans_s1" style={{ marginBottom: 0 }}>Tue, Wed, Thu, Fri, Sat: 9am - 5pm</p>
          <p className="p__opensans_s1">Sun, Mon: Closed</p>
        </div>
      </div>

    </div>

    <div className="contactus__copyright">
      <p className="p__opensans_s1">©2022 Jinny Cake Cafe. All Rights reserved.</p>
    </div>

  </div>
);

export default ContactUs;
