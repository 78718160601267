import bg from '../assets/bg.png';
import G from '../assets/G.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.svg';
import map from '../assets/map.svg';
import clock from '../assets/clock.svg';
import logoText from '../assets/logo_text.svg';
import special1 from '../assets/special_1.png';
import special1Small from '../assets/special_1_small.png';
import special1Large from '../assets/special_1_large.png';
import special2 from '../assets/special_2.png';
import special2Small from '../assets/special_2_small.png';
import special2Large from '../assets/special_2_large.png';
import special3 from '../assets/special_3.png';
import special3Small from '../assets/special_3_small.png';
import special3Large from '../assets/special_3_large.png';

export default {
  bg,
  G,
  knife,
  logo,
  map,
  logoText,
  clock,
  special1,
  special1Large,
  special1Small,
  special2,
  special2Large,
  special2Small,
  special3,
  special3Small,
  special3Large,
};
