import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { data } from '../../constants';
import { SpecialItem } from '../../components';

import './Specials.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/autoplay/autoplay.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';

const Specials = () => (
  <div className="app__specials flex__center section__padding" id="specials">
    <>
      <Swiper
        style={{
          '--swiper-navigation-color': '#DCCA87',
          '--swiper-pagination-color': '#DCCA87',
          '--swiper-pagination-bullet-inactive-color': '#DCCA87',
          '--swiper-pagination-bullet-horizontal-gap': '12px',
          '--swiper-pagination-bullet-size': '12px',
        }}
        speed={1000}
        slidesPerView={1}
        spaceBetween={30}
        loop
        pagination={{
          clickable: true,
        }}
        navigation
        modules={[Pagination, Navigation, Autoplay]}
        autoplay={{ delay: 5000 }}
        className="app__specials-swiper"
      >
        {data.specials.map((special, index) => (
          <SwiperSlide className="app__specials-swiper_slide" key={`slide-${index}`} tag="li">
            <SpecialItem
              className="app__specials-swiper_slide_content"
              imageUrl={special.imgUrl}
              imageUrlSmall={special.imgUrlSmall}
              imageUrlLarge={special.imgUrlLarge}
              title={special.title}
              price={special.price}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  </div>
);

export default Specials;
