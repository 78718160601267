import React from 'react';

import { MessengerChat } from 'react-messenger-chat-plugin';
import { Menu, ContactUs, Specials } from './container';
import { Navbar } from './components';
import './App.css';

const App = () => (
  <div>
    <header className="app_header">
      <Navbar />
    </header>
    <main className="app_main app__bg">
      <Specials />
      <Menu />
      {/* <AboutUs /> */}
      <ContactUs />
    </main>
    <MessengerChat pageId="101340949370634" />
  </div>
);

export default App;
