import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdClose } from 'react-icons/md';
import images from '../../constants/images';
import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logoText} alt="app__logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans_s1"><a href="#specials">Specials</a></li>
        <li className="p__opensans_s1"><a href="#menu">Menu</a></li>
        <li className="p__opensans_s1"><a href="#about">About Us</a></li>
        <li className="p__opensans_s1"><a href="#contactus">Contact</a></li>
      </ul>
      <div className="app__navbar-order">
        <a href="https://forms.wix.com/2d1e77ba-0ce1-44a8-b7e8-a136d34990e1:7e53b10b-e617-4fb6-9c37-077a02f6cccc" target="_blank" rel="noopener noreferrer" className="p__opensans_s1">Pre-Order</a>
      </div>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdClose fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><a href="https://forms.wix.com/2d1e77ba-0ce1-44a8-b7e8-a136d34990e1:7e53b10b-e617-4fb6-9c37-077a02f6cccc" target="_blank" rel="noopener noreferrer" onClick={() => setToggleMenu(false)}>Pre-Order</a></li>
              <li><a href="#specials" onClick={() => setToggleMenu(false)}>Specials</a></li>
              <li><a href="#menu" onClick={() => setToggleMenu(false)}>Menu</a></li>
              <li><a href="#about" onClick={() => setToggleMenu(false)}>About Us</a></li>
              <li><a href="#contactus" onClick={() => setToggleMenu(false)}>Contact</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
